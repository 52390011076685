import { CapacityFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/capacity-filter/capacity-filter-schemas";
import { capacityFilterId } from "@/features/filtering/filters/capacity-filter/capacityFilterId";
import { DateTimeFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/date-time-filter/date-time-filter-schemas";
import { dateTimeFilterId } from "@/features/filtering/filters/date-time-filter/dateTimeFilterId";
import { FacilityFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/facility-filter/facility-filter-schemas";
import { facilityFilterId } from "@/features/filtering/filters/facility-filter/facilityFilterId";
import { PricingFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/pricing-filter/pricing-filter-schemas";
import { pricingFilterId } from "@/features/filtering/filters/pricing-filter/pricingFilterId";
import { ResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema } from "@/features/filtering/filters/resource-type-filter/resource-type-filter-schemas";
import { resourceTypeFilterId } from "@/features/filtering/filters/resource-type-filter/resourceTypeFilterId";
import { OpeningHourSchema } from "@/features/host-locations/domain/entities/OpeningHourSchema";
import { PictureSchema } from "@/features/host-locations/domain/entities/schemas/PictureSchema";
import { PricingOverviewInformationSchema } from "@/features/host-locations/domain/entities/schemas/PricingOverviewInformationSchema";
import { ProductSchema } from "@/features/host-locations/domain/entities/schemas/ProductSchema";
import { WorkspaceSchema } from "@/features/host-locations/domain/entities/schemas/WorkspaceSchema";
import { FacilitySchema } from "@/features/resource/domain/entities/FacilitySchema";
import { z } from "zod";

export const WorkspaceGroupFilterMetadata = z.object({
    // Filters can add metadata to the
    // `filterData` field, under a property
    // with the same name as the filter id.
    // Components can access this information
    // to adjust how they display their
    // contents.
    [pricingFilterId]:
        PricingFilterWorkspaceGroupLevelMetadataDataSchema.optional(),
    [capacityFilterId]:
        CapacityFilterWorkspaceGroupLevelMetadataDataSchema.optional(),
    [dateTimeFilterId]:
        DateTimeFilterWorkspaceGroupLevelMetadataDataSchema.optional(),
    [facilityFilterId]:
        FacilityFilterWorkspaceGroupLevelMetadataDataSchema.optional(),
    [resourceTypeFilterId]:
        ResourceTypeFilterWorkspaceGroupLevelMetadataDataSchema.optional(),
});

export type TWorkspaceGroupFilterMetadata = z.infer<
    typeof WorkspaceGroupFilterMetadata
>;

// TODO: add comments
export const WorkspaceGroupSchema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.string(),
    description: z.string(),
    pictures: PictureSchema.array(),
    max_occupants: z.number(),
    workspaces: WorkspaceSchema.array(),
    pricingInformation: PricingOverviewInformationSchema.optional(),
    amenities: FacilitySchema.array(),
    products: ProductSchema.array().optional(),
    opening_hours: OpeningHourSchema.array(),
    minimumBookingMinutes: z.number(),
    cancelLeewayMinutes: z.number(),

    // ########
    // Filtering

    // Indicates if the workspace group is a match to
    // filters considering the workspace group. Does
    // **NOT** consider if the location itself is a
    // match to filters considering the location.
    isMatch: z.boolean().default(true),
    filterMetadata: WorkspaceGroupFilterMetadata.optional(),
});
export type TWorkspaceGroupSchema = z.infer<typeof WorkspaceGroupSchema>;
