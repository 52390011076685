import {DeepReadonly} from "ts-essentials";

/**
 * Valid keys that can be retrieved from the environment variables **in server
 * and client components**.
 */
const _ClientSideAccessiblePublicEnvValues = {
    // in the form of [enumKey = process.env.NEXT_PUBLIC_[KEY_NAME]]

    // eslint-disable-next-line
    // @ts-ignore
    wezooApiUrl: process.env.NEXT_PUBLIC_WEZOO_API_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooBookingServiceUrl: process.env.NEXT_PUBLIC_WEZOO_BOOKING_SERVICE_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooPaymentServiceUrl: process.env.NEXT_PUBLIC_WEZOO_PAYMENT_SERVICE_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooLocationServiceUrl: process.env.NEXT_PUBLIC_WEZOO_LOCATION_SERVICE_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooLocationServiceUrlV2: process.env.NEXT_PUBLIC_WEZOO_LOCATION_V2_SERVICE_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooMediaServiceUrl: process.env.NEXT_PUBLIC_WEZOO_MEDIA_SERVICE_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooWebAppUrl: process.env.NEXT_PUBLIC_WEZOO_WEB_APP_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooLandingUrl: process.env.NEXT_PUBLIC_WEZOO_LANDING_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooMapboxAccessToken: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN,

    // eslint-disable-next-line
    // @ts-ignore
    wezooMapboxStylesUrl: process.env.NEXT_PUBLIC_MAPBOX_STYLES_URL,

    // eslint-disable-next-line
    // @ts-ignore
    wezooIntercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,

    // eslint-disable-next-line
    // @ts-ignore
    clarityId: process.env.NEXT_PUBLIC_CLARITY_ID,

    // eslint-disable-next-line
    // @ts-ignore
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,

    // eslint-disable-next-line
    // @ts-ignore
    gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH,

    // eslint-disable-next-line
    // @ts-ignore
    gtmEnvironment: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
};

export const ClientSideAccessiblePublicEnvValues: DeepReadonly<
    typeof _ClientSideAccessiblePublicEnvValues
> = _ClientSideAccessiblePublicEnvValues;
