import { DiscountsSchema } from "@/features/host-locations/domain/entities/DiscountsSchema";
import { RatingsSchema } from "@/features/host-locations/domain/entities/RatingsSchema";
import { AvailabilitySchema } from "@/features/host-locations/domain/entities/schemas/AvailabilitySchema";
import { CurrencySchema } from "@/features/host-locations/domain/entities/schemas/CurrencySchema";
import { LocationLevelFilterMetadata } from "@/features/host-locations/domain/entities/schemas/HostLocationGeneralInformationSchema";
import { HostLocationPricingInformationSchema } from "@/features/host-locations/domain/entities/schemas/HostLocationPricingInformationSchema";
import { OperatorSchema } from "@/features/host-locations/domain/entities/schemas/operator/OperatorSchema";
import { PictureSchema } from "@/features/host-locations/domain/entities/schemas/PictureSchema";
import { PricingOverviewInformationSchema } from "@/features/host-locations/domain/entities/schemas/PricingOverviewInformationSchema";
import { WorkspaceGroupFilterMetadata } from "@/features/host-locations/domain/entities/schemas/WorkspaceGroupSchema";
import { z } from "zod";

export const SimplifiedFacilitySchema = z.object({
    type: z.string(),
    slug: z.string(),
    group: z.string().optional(),
});

export const SimplifiedHostLocationGeneralInformationSchema = z.object({
    location: z.object({
        id: z.string(),
        slug: z.string(),
        name: z.string(),
        currency: CurrencySchema,
        latitude: z.number(),
        longitude: z.number(),
        pictures: PictureSchema.array(),
        services: SimplifiedFacilitySchema.array(),
        operator: OperatorSchema.optional(),
        pricingInformation: HostLocationPricingInformationSchema.optional(),
        ratings: RatingsSchema.array().optional(),
        discounts: DiscountsSchema.array(),
    }),
    // ########
    // Filtering

    // Indicates if the
    // location is a match,
    // i.e. if the location
    // characteristics
    // fulfill all filter
    // requirements. Does
    // **NOT** consider if
    // there are matching
    // workspace groups.
    isMatch: z.boolean().default(true),
    filterMetadata: LocationLevelFilterMetadata.optional(),
});
export const SimplifiedWorkspaceSchema = z.object({
    id: z.string(),
    availability: AvailabilitySchema.array(),
    allocations: z.number(),
});

export const SimplifiedWorkspaceGroupSchema = z.object({
    id: z.string(),
    type: z.string(),
    max_occupants: z.number(),
    workspaces: SimplifiedWorkspaceSchema.array(),
    pricingInformation: PricingOverviewInformationSchema.optional(),
    amenities: SimplifiedFacilitySchema.array(),
    minimumBookingMinutes: z.number(),
    cancelLeewayMinutes: z.number(),

    // ########
    // Filtering

    // Indicates if the workspace group is a
    // match to filters considering the
    // workspace group. Does **NOT**
    // consider if the location itself is a
    // match to filters considering the
    // location.
    isMatch: z.boolean().default(true),
    filterMetadata: WorkspaceGroupFilterMetadata.optional(),
});

export const SimplifiedHostLocationWithWorkspaceGroupsSchema =
    SimplifiedHostLocationGeneralInformationSchema.and(
        z.object({
            workspace_groups: SimplifiedWorkspaceGroupSchema.array(),
        })
    );
export type TSimplifiedHostLocationWithWorkspaceGroupsSchema = z.infer<
    typeof SimplifiedHostLocationWithWorkspaceGroupsSchema
>;
